import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { switchMap } from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';

import { AnaplanCommunity, CreateSiteParam, ImportAnaplanParam, JobQueueParam } from '../models';
import { AnaplanService, AuthorizationService, JobQueueService, SiteService } from '../services';
import { JobQueueListComponent } from '../shared';

@Component({
    selector: 'app-createsite',
    templateUrl: './createsite.component.html',
    styleUrls: ['./createsite.component.css']
})
export class CreatesiteComponent implements OnInit {
    @ViewChild(JobQueueListComponent)
    jobQueueChild: JobQueueListComponent;

    selAnaplanCommunity: any;
    createJobErrorMessage: string = '';
    dateImported: string;
    selectedFeasyCode: string;
    selFeasyCode: string;
    loginRoles: string[];
    mstrCommId: number = 0;
    sharepointSiteMasterCommunityIds: number[] = [];
    importData: boolean;
    isLoading: boolean;
    showImported: boolean;
    showNotImported: boolean;
    showSiteExists: boolean = false;

    anaplanFeasyList: AnaplanCommunity[] = [];

    get authenticated(): boolean {
        return !!this.authService.authenticated();
    }

    get disableImportAnaplanCommunities(): boolean {
        const jobAlreadyAdded = (element) => element.jobTypeDescription == "IMPORT ANAPLAN COMMUNITIES" && (element.jobStatus == "IN-QUEUE" || element.jobStatus == "IN-PROGRESS");
        return (this.jobQueueChild?.jobQueueList.length > 0 && this.jobQueueChild?.jobQueueList.findIndex(jobAlreadyAdded) > -1);
    }

    get enableCreateSite(): boolean {
        return (this.showImported || this.importData) && !this.showSiteExists && !this.isLoading && this.mstrCommId !== 0;
    }

    get username(): string {
        return this.authService.getUsername();
    }

    constructor(private anaplanService: AnaplanService, private authService: AuthorizationService, private jobQueueService: JobQueueService, private messageService: MessageService, private siteService: SiteService) { }

    ngOnInit(): void {
        this.isLoading = true;
        this.showNotImported = false;
        this.showImported = false;
        this.importData = false;
        this.selectedFeasyCode = null;

        this.loginRoles = this.authService.getRoles();

        this.anaplanService.anaplanDivisionCommunities(this.loginRoles).subscribe((recordset) => {
            this.anaplanFeasyList = recordset;
            this.isLoading = false;
        }, err => {
            console.log(err);
            this.anaplanFeasyList = [];
            this.isLoading = false;
        });
    }

    checkImport(event: any): void {
        this.importData = event;
    }

    onMasterCommChange(val): void {
        this.isLoading = true;
        this.showImported = false;
        this.showNotImported = false;
        this.showSiteExists = false;
        this.mstrCommId = val;
        this.selectedFeasyCode = val;

        forkJoin(
            this.anaplanService.getAnaplanFeasyMasterCommunity(val),
            this.siteService.getCommunitySite(val)
        ).subscribe(([afmc, cs]) => {
            if (!afmc[0]) {
                this.showNotImported = true;
                this.showImported = false;
                this.showSiteExists = false;
            } else {
                let date = afmc[0].dateCreated.toString();
                const splitDate = date.split('T');
                date = splitDate[0] + ' ' + splitDate[1] + ' UTC';
                const dateCreated = new Date(date);
                this.dateImported = dateCreated.toString();

                this.showNotImported = false;
                this.showImported = true;
                this.showSiteExists = cs !== null;
            }

            this.isLoading = false;
        }, err => {
            console.log(err);
        });
    }

    onCreateSiteClick(): void {
        this.createJobErrorMessage = '';  // reset error message

        const feasy = this.selectedFeasyCode.split('^^');
        const user = this.username.split('@');

        // stub out mastercommid
        const csparam: CreateSiteParam = new CreateSiteParam();
        csparam.anaplanFeasyName = feasy[0];
        csparam.anaplanFeasyId = this.selectedFeasyCode;
        csparam.area = 'Central';

        this.isLoading = true;
        this.siteService.createMasterCommunity(csparam).pipe(
            switchMap((mstrCommId: number) => {
                // stub master community then add to job queue after getting master community id
                this.mstrCommId = mstrCommId;
                const siteParam: JobQueueParam = new JobQueueParam();
                siteParam.mstrCommId = this.mstrCommId;
                siteParam.jobType = 6; // Create Site
                siteParam.submittedByUser = user[0].toLowerCase();
                return this.jobQueueService.postJobQueue(siteParam, this.importData);
            })
        ).subscribe(() => {
            this.isLoading = false;

            if (this.importData) {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Added',
                    detail: 'Import Anaplan Data request added to queue'
                });
            }

            this.messageService.add({
                severity: 'success',
                summary: 'Added',
                detail: 'Create Site request added to queue'
            });

            this.jobQueueChild.getJobQueues();
        }, (err: HttpErrorResponse) => {
            if (err.status === 409) {
                // HTTP Conflict, Job Type Already Exists
                this.createJobErrorMessage = 'Create Site already scheduled for' + feasy[0];
            } else {
                console.log(err);
                this.isLoading = false;

                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: err.message,
                });
            }
        });
    }

    onImportAnaplanCommunitiesClick(): void {
        this.createJobErrorMessage = '';  // reset error message        
        const user = this.username.split('@');

        const importParam: JobQueueParam = new JobQueueParam();
        importParam.mstrCommId = 0;
        importParam.jobType = 8;
        importParam.submittedByUser = user[0].toLowerCase();

        this.jobQueueService.postJobQueue(importParam).subscribe(() => {
            this.messageService.add({
                severity: 'success',
                summary: 'Added',
                detail: 'Import Anaplan Communities request added to queue'
            });

            this.jobQueueChild.getJobQueues();
        }, (err: HttpErrorResponse) => {
            if (err.status === 409) {
                // HTTP Conflict, Job Type Already Exists
                this.createJobErrorMessage = 'Import Anaplan Communities already scheduled';
            } else {
                console.log(err);
                this.isLoading = false;

                this.messageService.add({
                    severity: 'error',
                    summary: 'Error',
                    detail: err.message,
                });
            }
        });
    }
}
