import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { SiteBanner } from '../../models';
import { AuthorizationService, SiteBannerService } from '../../services';

@Component({
    selector: 'app-site-banner',
    templateUrl: './site-banner.component.html',
    styleUrls: ['./site-banner.component.css']
})
export class SiteBannerComponent implements OnInit {

    @Input() siteBannerInput: SiteBanner = new SiteBanner();
    @Input() isManager: boolean = false;
    @Input() isAdminPage: boolean = false;
    @Output() bannerEvent = new EventEmitter<boolean>();

    isLoading: boolean = false;
    siteBanner: SiteBanner;
    displayClass: string;
    currentRoute: string = null;

    get username(): string {
        return this.authService.getUsername();
    }

    constructor(private authService: AuthorizationService, private router: Router, private siteBannerService: SiteBannerService) { }

    ngOnInit(): void {
        this.siteBanner = new SiteBanner();
        if (this.isManager) {
            this.siteBanner = this.siteBannerInput;
            this.displayClass = 'in';
        } else {
            this.getSiteBannerInfo();
            this.displayClass = '';
        }
    }

    checkAdminPage(): boolean {
        if (this.isManager) {
            return false;
        } else {
            this.currentRoute = this.router.url.replace("/", "");
            return this.currentRoute?.toLowerCase().indexOf("admin") > -1;
        }
    }

    getSiteBannerInfo(): void {
        this.siteBannerService.getSiteBannerInfo().subscribe((data) => {
            this.siteBanner = data;
            this.bannerEvent.emit(this.siteBanner.isDisplayed);
        });
    }

    setHidden(status: boolean): void {
        this.siteBannerService.userHasClosed = status;
        if (this.siteBannerService.userHasClosed) {
            this.displayClass = '';
        } else {
            this.displayClass = 'in';
        }
    }
}
