import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timer'
})
export class TimerPipe implements PipeTransform {

    // This pipe takes a number of seconds and returns a string in the format of "X hours, Y minutes, Z seconds"
    transform(value: number): string {
        const hours = Math.floor(value / 3600);
        const minutes = Math.floor((value % 3600) / 60);
        const seconds = value % 60;

        var returnVal = ''
        if (hours > 0) {
            returnVal = `${hours.toString()} hours, `;
        }
        if (minutes > 0) {
            returnVal += `${minutes.toString()} minutes, `;
        }
        if (seconds > 0) {
            returnVal += `${seconds.toString()} seconds`;
        }
        return returnVal;
    }
}
