// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    baseUrl: 'https://feasyapi.staging.pulte.com',
    azureClientId: '8375cbe5-d0b4-4cd3-9ab0-4920accab4c6',
    sharepointSiteUrl: 'https://pulte.sharepoint.com/sites/staging/landfeasibilitybookstg',
    documentationSiteUrl: 'https://pulte.sharepoint.com/sites/staging/landfeasibilitybookstg/How%20To/Forms/AllItems.aspx',
    anaplanUrl: 'https://anaplan.pulte.com',
    name: 'STG'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
