import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { JobQueueParam, JobTriggerParam } from '../models';
import { SharedService } from '.';

@Injectable()
export class JobQueueService {

    constructor(public http: HttpClient, private sharedService: SharedService) { }

    cancelJob(jobQueueId: string): Observable<any> {
        const opts = this.sharedService.getOptsJson();
        const getUrl = environment.baseUrl + '/JobQueue/';
        return this.http.post(`${getUrl}Cancel`, { jobQueueId }, opts);
    }

    getJobQueues(): Observable<any> {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/JobQueue/';
        return this.http.get(`${getUrl}get`, opts);
    }

    getJobTriggerStatus(): Observable<any> {
        const opts = this.sharedService.getOpts();
        const getUrl = environment.baseUrl + '/JobQueue/';
        return this.http.get(`${getUrl}GetJobTrigger`, opts);
    }

    updateJobTriggerStatus(jobTriggerParam: JobTriggerParam): any {
        const opts = this.sharedService.getOptsJson();
        const postUrl = environment.baseUrl + '/JobQueue/';
        //  have to re create the model then call on stringify
        //  stringify converts number 4++ digits long to string so it ends up
        //  not matching the API call param signature and not making it
        //  into the API!
        const data = JSON.stringify({
            isAutoJobActive: jobTriggerParam.isAutoJobActive,
            isProcessorActive: jobTriggerParam.isProcessorActive
        });
        return this.http.post(`${postUrl}UpdateJobTrigger`, data, opts);
    }

    postJobQueue(jobQueueParam: JobQueueParam, isImportData: boolean = false): any {
        const opts = this.sharedService.getOptsJson();
        const postUrl = environment.baseUrl + '/JobQueue/';
        //  have to re create the model then call on stringify
        //  stringify converts number 4++ digits long to string so it ends up
        //  not matching the API call param signature and not making it
        //  into the API!
        const data = JSON.stringify({
            mstrCommId: Number(jobQueueParam.mstrCommId),
            jobType: jobQueueParam.jobType,
            submittedByUser: jobQueueParam.submittedByUser
        });
        return this.http.post(`${postUrl}Create/${isImportData}`, data, opts);
    }
}
