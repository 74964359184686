export class JobStatData {
    jobTypeName: string;
    jobType: JobTypeEnum;
    jobCount: number;
}

export class JobStatDataAverageExecutionTime extends JobStatData {
    averageWaitTime: number;
    averageExecutionDuration: number;
    averageTotalDuration: number;
}

export class JobStatDataErrorBreakdown extends JobStatData {
    errorCount: number;
    successCount: number;
    cancelCount: number;
    errorPercentage: number;
    successPercentage: number;
    cancelPercentage: number;
    topErrorMessages: TopErrorMessages[];
}

export class TopErrorMessages {
    messageText: string;
    timesOccured: number;
}

export enum JobTypeEnum {
    CreateBook = 0,
    PreFeasyBook = 1,
    FullBook = 2,
    ExecutiveBook = 3,
    BBook = 4,
    RefreshLinks = 5,
    CreateSite = 6,
    ImportAnaplanData = 7,
    ImportAnaplanCommunities = 8
}
