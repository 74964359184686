<nav id="sidebar">
    <div class="sidebar-header">
        <img src="assets/pulte_logo.png">
        <h3 class="title"><i class="fa fa-cogs fa-lg"></i>&nbsp;&nbsp;Feasy Utility</h3>
    </div>

    <ul class="list-unstyled components" *ngIf="currentRoute">
        <li>
            <a href="/reports" [class.selected]="isReportsSelected"><i class="fa fa-paper-plane fa-lg"></i>
                &nbsp;&nbsp;&nbsp;Reports</a>
        </li>
        <li>
            <a href="/createsite" [class.selected]="isCreateSiteSelected"><i class="fa fa-sitemap fa-lg"></i>
                &nbsp;&nbsp;&nbsp;Create Site</a>
        </li>
        <li>
            <a href="/refreshlinks" [class.selected]="isRefreshLinksSelected"><i class="fa fa-link fa-lg"></i>
                &nbsp;&nbsp;&nbsp;Refresh Links</a>
        </li>
        <li>
            <a href="/createbook" [class.selected]="isCreateBookSelected"><i class="fa fa-book fa-lg"></i>
                &nbsp;&nbsp;&nbsp;Create Book</a>
        </li>
        <li>
            <a href="{{sharepointSiteUrl}}" target="new"><i class="fa fa-eye fa-lg"></i> &nbsp;&nbsp;&nbsp;View
                Sites</a>
        </li>
        <li>
            <a href="{{anaplanUrl}}" target="new"><i class="fa fa-cog fa-lg"></i> &nbsp;&nbsp;&nbsp;Anaplan Tool</a>
        </li>
        <li *ngIf="isAdmin">
            <a href="#adminList" [class.selected]="isAdminSelected" data-toggle="collapse">
                <i class="fa fa-user-secret fa-lg"></i> &nbsp;&nbsp;&nbsp;Admin <span class="expandCarrot"><i
                        class="pi pi-angle-right"></i></span>
            </a>
        </li>
        <div *ngIf="isAdmin" id="adminList" class="collapse out" aria-expanded="false" aria-controls="adminList">
            <li>
                <a href="/admin/job-queue-manager" [class.selected]="isJobQueueManagerSelected"><i
                        class="fa fa-list fa-lg"></i> &nbsp;&nbsp;&nbsp;Job Queue Manager</a>
            </li>
            <li>
                <a href="/admin/job-stats" [class.selected]="isJobStatsSelected"><i class="fa fa-history fa-lg"></i>
                    &nbsp;&nbsp;&nbsp;Job Statistics</a>
            </li>
            <li>
                <a href="/admin/org-manager" [class.selected]="isOrgManagerSelected"><i class="fa fa-map fa-lg"></i>
                    &nbsp;&nbsp;&nbsp;Org Manager</a>
            </li>
            <li>
                <a href="/admin/site-banner" [class.selected]="isSiteBannerSelected"><i class="fa fa-flag fa-lg"></i>
                    &nbsp;&nbsp;&nbsp;Site Banner Manager</a>
            </li>
        </div>
        <li>
            <a href="{{documentationSiteUrl}}"><i class="fa fa-info-circle fa-lg"></i> &nbsp;&nbsp;&nbsp;Info</a>
        </li>
    </ul>
</nav>